import { Component, inject, Inject, signal, TemplateRef, ViewChild, viewChild, ViewContainerRef, WritableSignal } from '@angular/core';
import {DIALOG_DATA, DialogRef} from '@angular/cdk/dialog';
import { CommonModule, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormElementComponent } from './fe/form-element.component';
import { InpComponent } from './fe/inp.component';
import { DropdownComponent } from "./fe/dropdown/dropdown.component";
import { SliderComponent } from './fe/slider.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonService } from '../common.service';
import { concatMap, filter, from, map, Observable, of, tap } from 'rxjs';
import { TooltipDirective } from './directive/tooltip.directive';
import { TooltipShowDirective } from './directive/tooltip-show.directive';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-dialog',
  standalone: true,
  imports: [CommonModule,NgIf, MatButtonModule, MatIconModule, MatTableModule, MatCheckboxModule, FormElementComponent, DropdownComponent,InpComponent, SliderComponent, TooltipDirective, TooltipShowDirective],
  template: `
    <div *ngIf="modalData !== null" class="modal">
      <div class="modal-title">
        <h3>
          {{title}}
        </h3>
      </div>
      <div class="modal-body mat-mdc-dialog-content mdc-dialog__content">
            <div  class="flex flex-row justify-start gap-8 ">
              <!-- <p>dialog data</p>
              @for(q of modalData; track q.id) {  
                Name: {{q.id}}--{{q.name}}--{{q.username}}
              } -->

              <!-- (change)="onFiltersCombined($event)" -->
              <!-- <mat-checkbox #combi class="mr-4" (change)="onFiltersCombined($event)">Combined</mat-checkbox> -->

              @switch (dataType) {
                @case ('users') {
                  <appformelement>
                    
                    <label label>Name</label> 
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'name'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>

                  </appformelement>
                  <appformelement>

                    <label label>User Name</label>
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'username'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
              
                  </appformelement>
                }
                @case('posts'){
                  <appformelement>
                    <label label>Title</label> 
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'title'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                  </appformelement>
                  <appformelement>
                  
                    <label label>Body</label>
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'body'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                  
                  </appformelement>
                }
                @case('comments'){
                  <appformelement>
                    <label label>Name</label> 
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'name'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                  </appformelement>
                  <appformelement>
                    <label label>Email</label>
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'email'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                  </appformelement>
                  <appformelement>
                    <label label>Body</label>
                    <div ele><app-inp  [dataType]="dataType" [selectedUsers]="selectedUsers" [isFiltersCombined]="isFiltersCombined()" [filterOn]="'body'" (combinedFilteredOutput)="handleCombinedFilteredOutput($event)" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                  </appformelement>
                }
                @default {}
              } <appformelement> 
        
                    <label label>ID Selection Range</label>
                    <div ele>
                      <app-slider [selectedUsers]="selectedUsers" [dataType]="dataType" (filteredOutput)="handleFilteredOutput($event)">
                      </app-slider>
                    </div>

                </appformelement>
            </div>
          <div class="table-overflow-wrap">
            <table mat-table [dataSource]="modalData">
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              
              @for(col of displayedColumns; track col; let i = $index){
                @if(col==="selec") {
                  <ng-container matColumnDef="{{col}}">
                       <th mat-header-cell *matHeaderCellDef>
                          <mat-checkbox (change)="$event ? toggleAllRows() : null"
                                        [checked]="selection.hasValue() && isAllSelected()"
                                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                                     >
                          </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                          <mat-checkbox (click)="$event.stopPropagation()"
                                        (change)="$event ? selection.toggle(row) : null"
                                        [checked]="selection.isSelected(row)"
                                     >
                          </mat-checkbox>
                        </td>
                  </ng-container>
                } @else {
                    <ng-container matColumnDef="{{col}}">
                      <th mat-header-cell *matHeaderCellDef>{{col}}</th>
                      <td mat-cell *matCellDef="let el">
                        @if(col === 'address'){
                          <div>City: <span (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col)" (mouseleave)="onTooltipLeave()">{{el?.[col]['city']}}</span></div>
                          <div>ZipCode: <span (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col)" (mouseleave)="onTooltipLeave()">{{el?.[col]['zipcode']}}</span></div>
                        } @else if(col === 'id'){ 
                            <div class="flex">
                                <div (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col)" (mouseleave)="onTooltipLeave()">{{el?.[col]}} </div>
                                <mat-icon>info</mat-icon>
                            </div>
                        } @else if(col === 'body'){ 
                            <!-- {{el?.[col]}} -->
                            <div class="flex">
                                <div (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col,displayedColumns)" (mouseleave)="onTooltipLeave()">{{el?.[col]}} </div>
                            </div>
                        } @else if(col === 'website'){ 
                            <div class="flex">
                                <div (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col)" (mouseleave)="onTooltipLeave()">{{el?.[col]}} </div>
                                <mat-icon>info</mat-icon>
                            </div>
                        }  @else {
                          <div (mousemove)="pointerOnCellMove($event,col)" (mouseenter)="onTooltipEnter($event,col)" (mouseleave)="onTooltipLeave()">{{el?.[col]}}</div>
                        }
                      </td>
                    </ng-container>
                  }
              }
              <!-- <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let el">{{el?.name}}</td>
              </ng-container>
              <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef>Username</th>
                <td mat-cell *matCellDef="let el">{{el?.username}}</td>
              </ng-container>
              <ng-container matColumnDef="website">
                <th mat-header-cell *matHeaderCellDef>Website</th>
                <td mat-cell *matCellDef="let el">{{el?.website}}</td>
              </ng-container>
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let el">{{el?.email}}</td>
              </ng-container>
                <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Phone</th>
                <td mat-cell *matCellDef="let el">{{el?.phone}}</td>
              </ng-container>
              <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef>Company</th>
                <td mat-cell *matCellDef="let el">
                  <div>Bs: {{el?.company?.bs}}</div>
                  <div>Name: {{el?.company?.name}}</div>
                </td>
              </ng-container>
              <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef>Address</th>
                <td mat-cell *matCellDef="let el">
                  <div>City: {{el?.address?.city}}</div>
                  <div>Street: {{el?.address?.street}}</div>
                  <div>ZipCode: {{el?.address?.zipcode}}</div>
                </td>
              </ng-container> -->
            </table>
          </div>
        
      </div>
      <div class="modal-actions">
        <div class="flex gap-4">
          @if(this.dataType=="users") {
            <button mat-flat-button color="primary" (click)="displayPostsFromSelectedUsers()">Get posts from selected users</button>
          }@else if(this.dataType=="posts") {
            <button mat-flat-button color="primary" (click)="displayCommentsFromSelectedPosts()">Get comments from selected posts</button>
          }
          <button mat-flat-button color="secondary" (click)="dialogRef.close()">Cancel</button>
        </div>
      </div>
    </div>

    <div class="cellTempDiv" [appTooltipShow] [@openClose]="tooltipAnimationState">
        <ng-container #cellTempDiv  ></ng-container>
    </div>
    
    <ng-template #cellTemp let-name="name" let-content="val" >
        <div class="text-sm">
        <div class="cellTempDiv--name  " >{{name}}: {{content}}</div>
        <!-- <div *sqs="{templ:sqsTemp, id:val}">qqqq</div> -->
            @if(dataType==='users' && (name==='id' || name==='website')) {
              <ng-template [sqs]="{dataType, name, content}" column_name="{{name}}" let-content="content" let-name="name" let-result="result" let-loading="loading">
                    <ng-container *ngIf="name=='id' && result.length">
                      <div class="w-full border-t-2 border-t-cyan-950"></div>
                        <div class="mt-2"></div>
                          @if(loading){
                            <p>Loading...</p>
                          } @else {
                        <h5>Posts written by this user:</h5>
                          @for(r of result; track r.id) {
                            <p>ID: {{r.id}} | Post Name: {{r.title}}</p>
                          }
                        }
                    </ng-container>
                    <ng-container *ngIf="name=='website'">
                      <div class="w-full border-t-2 border-t-cyan-950"></div>
                      <div class="mt-2"></div>
                        @if(loading){
                          <p>Loading...</p>
                        } @else {
                        <h5>Other user's Website matching this website domain extension:</h5>
                        @if(result.length) {
                          @for(r of result; track r.id) {
                            <p>Name: {{r.name}} | Website: {{r.website}}</p>
                          }
                        } @else {
                          <p>None</p>
                        }
                      }
                    </ng-container>
              </ng-template>
            }
            @else if(dataType==='posts' && (name==='id' || name==='body')) {
              <ng-template [sqs]="{dataType, name, content}" column_name="{{name}}" let-content="content"    let-name="name" let-result="result"  let-animateClass="animateClass" let-animateComplete="animateComplete"  let-loading="loading" >
                <div class="w-full border-t-2 border-t-cyan-950"></div>
                @if(name==='id'){
                  <div class="mt-2 ">
                    @if(loading){
                      <p>Loading...</p>
                    } @else {
                      <div *ngIf="animateComplete; else animateRunningRef;">
                        <h5>Comments for the selected Post</h5>
                          @for(q of result; track q.id) {
                            <div [@slideComments]="animateClass" [ngStyle]="{transform: 'translateX(100%)'}">
                              <p>Id: {{q.id}} | Email: {{q.email}} | Comment Title: {{q.name}}</p>
                            </div>
                          }
                      </div>
                      <ng-template #animateRunningRef>
                        <h5>Comment for the selected Post</h5>
                        <div [@slideComments]="animateClass" [ngStyle]="{transform: 'translateX(100%)'}">
                          <p>Id: {{result.id}} | Email: {{result.email}} | Comment Title: {{result.name}}</p>
                        </div>
                      </ng-template>
                    }
                  </div>
                }
              </ng-template>
            }
        </div>
    </ng-template>

    <!-- <ng-template #sqsTemp let-content="content">
      <p>s aaaa qqqqq tt</p>
      <p>content: {{content}}</p>
    </ng-template> -->
  `,
  styles:[
    `:host {background:white;}`
  ],
  animations: [
    trigger('slideComments', [
      state(
        'slide',
        style({
          transform: 'translateX(100%)',
        }),
      ),
      state(
        'slideOut',
        style({
          transform: 'translateX(-100%)',
        }),
      ),
      state(
        'slideIn',
        style({
          transform: 'translateX(0)',
        }),
      ),
      transition('* => slideIn', [animate('0.4s')]),
      transition('* => slide', [animate('0s')]),
    ]),
    trigger('openClose', [
      state(
        'open',
        style({
          height: 'auto',
          padding: '4px 10px',
          backgroundColor: 'yellow',
        }),
      ),
      state(
        'close',
        style({
          height: '0px',
          padding: '0px 0px',
          backgroundColor: 'blue',
        }),
      ),
      transition('* => close', [animate('1s')]),
      transition('* => open', [animate('0.5s')]),
    ])
    ,
  ],
})
export class DialogComponent {
  public modalData:any | undefined=null;
  title:string='';
  displayedColumns:string[]=[];
  selectedUsers:number[]=[];
  dataType:string='';
  isFiltersCombined:WritableSignal<boolean>=signal<boolean>(false);
  dialogRef = inject<DialogRef<string>>(DialogRef<string>);
  dataSource = new MatTableDataSource<any>(this.modalData);
  selection = new SelectionModel<any>(true, this.modalData);
  $nameOb:Observable<any>=new Observable<any>();
  @ViewChild('cellTemp',{read:TemplateRef<unknown>}) cellTemp!:TemplateRef<unknown>;
  @ViewChild('cellTempDiv',{read:ViewContainerRef}) cellTempDiv!:ViewContainerRef;
  ts=viewChild<TooltipShowDirective | undefined>(TooltipShowDirective);
  tooltipAnimationState:string='';
  slideAnimationState:string='';
  constructor(
    @Inject(DIALOG_DATA) data:any,
    private _cs:CommonService
  ){  
    this.modalData=data.data;
    this.title=data.title;
    console.log('modalData',data);
    this.dataSource = new MatTableDataSource<any>(this.modalData);
    this.selection = new SelectionModel<any>(true, this.modalData);
    this.selectedUsers=data.selectedUsers;
    this.dataType=data.dataType;
    this.displayedColumns = Object.keys(this.modalData[0])
    this.displayedColumns.unshift('selec')
  }

  

  isAllSelected(){
    const numSelected = this.selection?.selected?.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows
  }
  
  toggleAllRows(){
    console.log("this.dataSource?.data",this.dataSource?.data)
    if(this.isAllSelected()){
      this.selection?.clear();
      return;
    }
    
    this.selection.select(...this.dataSource?.data);
  }

  // users/posts/comments - datatable - individual filters
  handleFilteredOutput(data:any) {
    console.log("handleFilteredOutput",data);
    this.modalData = data;
  }

  // users/posts/comments - datatable - combined filters
  handleCombinedFilteredOutput(data:any) {
    console.log("handle--CombinedFilteredOutput",this.dataType,data);
    var dataTypeT = this.dataType === 'posts' ? this._cs.getPosts() : this._cs.getUsers();

    // var reqFilters:any[] = [];
    // reqFilters.push(data);

    // from(reqFilters).pipe(
    //   map(j => j.filter((q:any) => q.name.includes(data.value)))
    // )

    // switch (data.prop) {
    //   case 'name':
    //     this.$nameOb = dataTypeT.pipe(

    //       map(r => r.filter((s:any) => this.selectedUsers?.includes(s.id) && s.name.includes(data.value)))
    //     )
    //     break;
    //   case 'username':
    //     this.$nameOb = dataTypeT.pipe(
    //       map(r => r.filter((s:any) => this.selectedUsers?.includes(s.id) && s.username.includes(data.value)))
    //     )
    //     break;
    //   case 'title':
    //     this.$nameOb = dataTypeT.pipe(
    //       map(r => r.filter((s:any) => this.selectedUsers?.includes(s.id) && s.title.includes(data.value)))
    //     )
    //     break;
    //   case 'body':
    //     this.$nameOb = dataTypeT.pipe(
    //       map(r => r.filter((s:any) => this.selectedUsers?.includes(s.id) && s.body.includes(data.value)))
    //     )
    //     break;
    // }
    // this.$nameOb.subscribe({
    //   next:(g)=>{
    //     console.log("handleCombinedFilteredOutput-------result",g);
    //     this.modalData = g;
    //   }
    // })
  }

  onFiltersCombined(isCombined:any){
    console.log("isCombined",isCombined.checked)
    this.isFiltersCombined.update(()=>isCombined.checked);
  }

  // display comments, from selected posts
  displayCommentsFromSelectedPosts(){
    let selectedPostsId:number[]=[];
    this.selection.selected.map((h:any)=>selectedPostsId.push(h.id));
    this._cs.getComments().pipe(
      map(r => r.filter((q:any) => selectedPostsId.includes(q.postId)))
    ).subscribe({
      next:(commentsDetails:any) => {
          let commentsDetailsId : number[] = [];
          commentsDetails.map((r:any) => commentsDetailsId.push(r.id));
          this._cs.openDialog('comments', commentsDetails, commentsDetailsId).closed.subscribe({
            next:(w)=>console.log('users/posts closed',w)
          })
      }
    })
  }

  // display posts, from selected users
  displayPostsFromSelectedUsers(){
    console.log("this.selection.selected",this.selection.selected);
    let selectedUsersId:number[] = [];
    this.selection.selected.map((q:any)=>selectedUsersId.push(q.id))

    this._cs.getPosts()
    .pipe(
      //map(u => u.filter((k:any)=>k.userId ==)),
      map(w => w.filter((j:any) => selectedUsersId.includes(j.userId))),
    )
    .subscribe({
      next:(postsDetails:any) => {
          //console.log("postsDetails",postsDetails);
          let postsDetailsId : number[] = [];
          postsDetails.map((r:any) => postsDetailsId.push(r.id));
          this._cs.openDialog('posts', postsDetails, postsDetailsId).closed.subscribe({
            next:(w)=>console.log('users/posts closed',w)
          })
      }
    })
  }

  // onTooltipEnter(data?:any){
  //   this.ts()?.enter();
  //   let dataContent=data?.target?.textContent;
  // }

  onTooltipEnter(data:any,col:string,displayedColumns?:any){
    //this.ts()?.enter();
    this.cellTempDiv?.clear();
    let dataContent=data?.target?.textContent;
    // let cl;
    //  cl =  (typeof col == "object") ? col?.['city'] : cl = col;
    this.cellTempDiv?.createEmbeddedView(this.cellTemp, {name:col,val:dataContent,others:displayedColumns});
    this.tooltipAnimationState = 'open';
  }

  onTooltipLeave(){
    //this.ts()?.leave();
    this.tooltipAnimationState = 'close'
  }

  pointerOnCellMove(data:any,col:string){
    //console.log("processCellClick",data);
    let xx = data.clientX;
    let yy = data.clientY;
    this.ts()?.move(yy,xx);
  }
}
