import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
  standalone: true,
  pure:true
})
export class SortPipe implements PipeTransform {
  transform(value: any[], orderBy:string, dontSort?:boolean): any[] {
    // args.map((arg:any)=>{

    // })
    console.log("dontSort > ",dontSort);
    let arrVal = [...value];
    if(dontSort) return arrVal;
    return arrVal.sort((a:any,b:any)=>{
      if(orderBy === 'desc'){
          return b.id-a.id;
      } else if(orderBy === 'asc'){
          return a.id-b.id;
      } else {
        return 0;
      }
    })
  }
}
