<!-- <div class="note-pinned-{{note()?.pinned}}">({{note()?.id}}) - {{note()?.name}} - {{note()?.age}} -
  @if(!note()?.pinned) {
  <a (click)="removeNote(note()?.id)">Delete</a> |
  <a (click)="editNote(note()?.id)">Edit</a> |
  <a (click)="pinNote(note())">Pin</a> |
  <a (click)="selectNote(note())">Select</a>
  } @else {
  <a (click)="unpinNote(note())">UnPin</a> |
  }
</div> -->


<div class="grid-container note-pinned-{{note()?.pinned}}">
  <ng-content select="[noteId]"></ng-content>
  <ng-content select="[noteName]"></ng-content>
  <ng-content select="[noteAge]"></ng-content>
  <ng-content select="[noteEmail]"></ng-content>
  <ng-content select="[noteUsername]"></ng-content>
  <ng-content select="[noteWebsite]"></ng-content>
  <ng-content select="[noteAddressCity]"></ng-content>
  <ng-content select="[noteAddressZipcode]"></ng-content>
  <ng-content select="[noteCompanyName]"></ng-content>
  @if(!note()?.pinned) {
  <!-- <div class="grid-item-actions">
    <a #link (click)="removeNote(note()?.id);">Delete</a> |
    <a #link (click)="editNote(note()?.id);">Edit</a> |
    <a #link (click)="pinNote(note());">Pin</a> |
    <a #link (click)="selectNote(note());">Select</a>
  </div> -->
  } @else {
  <div class="grid-item-actions">
    <a (click)="unpinNote(note())">UnPin</a> |
  </div>
  }
</div>
