import { AfterViewInit, Component, ElementRef, inject, input, OnInit,  output, ViewChild, viewChild } from '@angular/core';
import {MatSliderModule} from '@angular/material/slider';
import { CommonService } from '../../common.service';
import { concatMap, map, of, range, tap, from, Observable, fromEvent } from 'rxjs';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-slider',
  standalone: true,
  imports: [MatSliderModule, MatCheckboxModule],
  template: `
    <div class="flex flex-row">
      <mat-checkbox #ai (change)="onFilterUsers()" class="mr-4">All</mat-checkbox>
      <mat-slider min="1" max="10" [disabled]="ai.checked">
        <input value="1" matSliderStartThumb (dragEnd)="onFilterUsers($event)">
        <input value="10" matSliderEndThumb (dragEnd)="onFilterUsers($event)">
      </mat-slider>
    </div>
  `,
  styles:[
    `mat-slider {
      width: 120px;
    }`
  ]
})
export class SliderComponent {
  filterOn = input<string>();
  _cs = inject(CommonService);
  idRange:number[] = [];
  filteredOutput = output<any[]>();
  selectedUsers = input<number[]>();
  query=new Observable();
  ai=viewChild('ai',{read:MatCheckbox});
  dataType = input<string>();
  constructor(){}

  // selectAllIds(data:any){
  //   console.log("select-all",data)
  // }

  // ngAfterViewInit(): void {
  //   fromEvent(this.ai?.nativeElement,'click').subscribe({
  //     next:(a)=>{
  //       //this.ai(). {this.onFilterUsers()}
  //       console.log("ai",a,this.ai)
  //     }
  //   })
  // }

  onFilterUsers(data?:any) {
    let isSelectAllChecked:boolean | undefined = this.ai()?.checked;
    
    //console.log("onFilterUsers",data);

     if(isSelectAllChecked) {
        this.query=this._cs.getUsers().pipe(
          map(r => r.filter((t:any)=> this.selectedUsers()?.includes(t.id)))
        )
      } else {
        this.idRange[data?.source?.thumbPosition-1]=data?.source?.value;
        var dataTypeT = this.dataType() === 'posts' ? this._cs.getPosts() : this._cs.getUsers();
        if(this.idRange[0]!==undefined || this.idRange[1]!==undefined) {
          console.log("this.idRange",this.idRange);
            this.query=dataTypeT.pipe(
              map(r => r.filter((t:any)=> this.selectedUsers()?.includes(t.id) && (t.id >= this.idRange[0] && t.id <= this.idRange[1])))
          );
        }
      }

    this.query.subscribe((res:any)=>{
        console.log("users",res)
        this.filteredOutput.emit(res);
    })
  }
}
