import { Component, EventEmitter, Inject, Input, input, OnInit, output } from '@angular/core';
import { ModalDirective } from '../directive/modal.directive';
import {MatTableModule} from '@angular/material/table';
import { FormElementComponent } from '../fe/form-element.component';
import { InpComponent } from '../fe/inp.component';
import { DropdownComponent } from "../fe/dropdown/dropdown.component";
import { SliderComponent } from '../fe/slider.component';

@Component({
  selector: 'userdetails-modal',
  standalone: true,
  imports: [ModalDirective, MatTableModule, FormElementComponent, InpComponent, DropdownComponent, SliderComponent],
  template: `
        <!-- <ng-template let-stu="students"> -->
          <div>
            <div [modalShow] class="modal-container">
              <a (click)="onModalClose()" class="position-absolute top-20 right-20">CloseModalC</a>
              selectedUsers -- {{selectedUsers}}
              <div class="modal">
                <!-- @for(ud of userDetails; track ud.id) {
                    <div>
                        <p>{{ud.id}} - {{ud.title}}</p>   
                    </div>
                } -->

                <!-- <app-form-element>
                  <label>eff</label>
                  
                    <app-inp [val]="'ssa'"></app-inp>
                  </ele>
                </app-form-element> -->

                <appformelement>
                  <p label>Name</p> 
                  <div ele><app-inp [filterOn]="'name'" [selectedUsers]="selectedUsers" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                </appformelement>

                <appformelement>
                  <p label>User Name</p>
                  <div ele><app-inp [filterOn]="'username'" [selectedUsers]="selectedUsers" (filteredOutput)="handleFilteredOutput($event)"></app-inp></div>
                </appformelement>

                <appformelement>
                  <p label>ID Selection Range</p>
                  <div ele>
                    <app-slider [selectedUsers]="selectedUsers" (filteredOutput)="handleFilteredOutput($event)">
                    </app-slider>
                  </div>
                </appformelement>

                <table mat-table [dataSource]="userDetails">
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                  @for(col of displayedColumns; track col){
                    <ng-container matColumnDef="{{col}}">
                      <th mat-header-cell *matHeaderCellDef>{{col}}</th>
                      <td mat-cell *matCellDef="let el">
                        @if(col === 'address'){
                          <div>City: {{el?.[col]['city']}}</div>
                          <div>ZipCode: {{el?.[col]['zipcode']}}</div>
                        } @else {
                          {{el?.[col]}}
                        }
                      </td>
                    </ng-container>
                  }
                  <!-- <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>Name</th>
                    <td mat-cell *matCellDef="let el">{{el?.name}}</td>
                  </ng-container>
                  <ng-container matColumnDef="username">
                    <th mat-header-cell *matHeaderCellDef>Username</th>
                    <td mat-cell *matCellDef="let el">{{el?.username}}</td>
                  </ng-container>
                  <ng-container matColumnDef="website">
                    <th mat-header-cell *matHeaderCellDef>Website</th>
                    <td mat-cell *matCellDef="let el">{{el?.website}}</td>
                  </ng-container>
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef>Email</th>
                    <td mat-cell *matCellDef="let el">{{el?.email}}</td>
                  </ng-container>
                   <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef>Phone</th>
                    <td mat-cell *matCellDef="let el">{{el?.phone}}</td>
                  </ng-container>
                  <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef>Company</th>
                    <td mat-cell *matCellDef="let el">
                      <div>Bs: {{el?.company?.bs}}</div>
                      <div>Name: {{el?.company?.name}}</div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef>Address</th>
                    <td mat-cell *matCellDef="let el">
                      <div>City: {{el?.address?.city}}</div>
                      <div>Street: {{el?.address?.street}}</div>
                      <div>ZipCode: {{el?.address?.zipcode}}</div>
                    </td>
                  </ng-container> -->
                </table>

              </div>
            </div>
          </div>
    <!-- </ng-template> -->
    `
})
export class ModalComponent implements OnInit {
  eventModalClose = output<any>();
  emc = new EventEmitter<any>()
   keyword = input<any>();
   variableName:string='';
   //selectedUsers = input<number[]>();
   @Input() selectedUsers:number[]=[];
  displayedColumns:any[]=[];
  constructor(@Inject('userDetails') public userDetails:any[]){
    this.displayedColumns = Object.keys(this.userDetails[0]);
  }

  ngOnInit(): void {
      //this.displayedColumns = Object.keys(this.userDetails[0]);
      //console.log("firstDataRow",firstDataRow);
      //this.displayedColumns
  }

  handleFilteredOutput(data:any){
    console.log("handleFilteredOutput",data)
    this.userDetails = data;
  }

  onModalClose(){
    this.emc.emit({close:true});
    this.eventModalClose.emit({close:true});
    console.log(this.emc,this.eventModalClose)
  }

}
