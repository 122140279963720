import { Component } from '@angular/core';
@Component({
  selector: 'appformelement',
  standalone: true,
  imports: [],
  template: `
      <ng-content select="[label]"></ng-content>
      <ng-content select="[ele]"></ng-content>
  `
})
export class FormElementComponent {

}
