import { Component, ElementRef, inject, input, OnInit, viewChild, ViewContainerRef,effect, output, Signal, ChangeDetectionStrategy } from '@angular/core';
import { MatInput, MatInputModule } from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import { debounceTime, distinctUntilChanged, from, fromEvent, map, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { CommonService } from '../../common.service';

@Component({
  selector: 'app-inp',
  standalone: true,
  imports: [MatInputModule, MatIconModule],
  template: `
    <mat-form-field appearance="fill">
      <!-- @if(isFiltersCombined()) {
        <input matInput #inp [type]="'text'" (change)="processInpCombined($event)" />
      } @else { -->
        <input matInput #inp [id]="filterOn()" [type]="'text'" />
        @if (inp.value) {
          <button matSuffix mat-icon-button aria-label="Clear" (click)="inp.value=''">
            <mat-icon>close</mat-icon>
          </button>
        }
      <!-- } -->
    </mat-form-field>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InpComponent{
  filterOn = input<string | any>();
  isFiltersCombined=input<any>();
  inp = viewChild('inp',{read:ElementRef});
  inpVRef = inject(ViewContainerRef);
  _cs = inject(CommonService);
  dataType = input<string>();
  filteredOutput = output<any>();
  combinedFilteredOutput = output<any>();
  selectedUsers = input<number[]>();
  query = new Observable();
  sub1 = new Subscription();
  sub2 = new Subscription();
 
  constructor(){
    effect(()=>{
      // this.inp()?.stateChanges.subscribe({
      //   next:(u)=>console.log('usrs',u)
      // })
      if(this.isFiltersCombined()) console.log("isFiltersCombined",this.isFiltersCombined())
      else console.log("isFiltersCombined---false",this.isFiltersCombined())


       var dataTypeT = this.dataType() === 'users' ? this._cs.getUsers() : this.dataType() === 'posts'  ? this._cs.getPosts() : this._cs.getComments();
        //console.log("this.isFiltersCombined()",this.isFiltersCombined());

      

      // if(!this.isFiltersCombined()){
        this.sub2.unsubscribe();
        switch (this.filterOn()) {
            case 'name':
              this.sub1 = fromEvent(this.inp()?.nativeElement , 'change').pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap((e) => dataTypeT.pipe(
                  map(r => r.filter((s:any) => this.selectedUsers()?.includes(s.id) && s.name.includes(this.inp()?.nativeElement.value)))
                ))
              ).subscribe((res:any) => {
                this.filteredOutput.emit(res)
              })
              break;
            case 'username':
              this.sub1 = fromEvent(this.inp()?.nativeElement , 'change').pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap((e) => dataTypeT.pipe(
                  map(r => r.filter((s:any) => this.selectedUsers()?.includes(s.id) && s.username.includes(this.inp()?.nativeElement.value)))
                ))
              ).subscribe((res:any) => {
                  this.filteredOutput.emit(res)
                })
              break;
            case 'title':
              this.sub1 = fromEvent(this.inp()?.nativeElement , 'change').pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap((e) => dataTypeT.pipe(
                  map(r => r.filter((s:any) => this.selectedUsers()?.includes(s.id) && s.title.includes(this.inp()?.nativeElement.value)))
                ))
              ).subscribe((res:any) => {
                this.filteredOutput.emit(res)
              })
              break;
            case 'body':
              this.sub1 = fromEvent(this.inp()?.nativeElement , 'change').pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap((e) => dataTypeT.pipe(
                  map(r => r.filter((s:any) => this.selectedUsers()?.includes(s.id) && s.body.includes(this.inp()?.nativeElement.value)))
                ))
              ).subscribe((res:any) => {
                this.filteredOutput.emit(res)
              })
              break;
            case 'email':
              this.sub1 = fromEvent(this.inp()?.nativeElement , 'change').pipe(
                debounceTime(1500),
                distinctUntilChanged(),
                switchMap((e) => dataTypeT.pipe(
                  map(r => r.filter((s:any) => this.selectedUsers()?.includes(s.id) && s.email.includes(this.inp()?.nativeElement.value)))
                ))
              ).subscribe((res:any) => {
                this.filteredOutput.emit(res)
              })
              break;
        }
      // } else {
      //   this.sub1.unsubscribe();
      //     this.sub2 = fromEvent(this.inp()?.nativeElement , 'change').subscribe({
      //     next:()=>{
      //       let inpVal = this.inp()?.nativeElement.value
      //       let inpType = this.inp()?.nativeElement.id
      //       this.combinedFilteredOutput.emit({prop:inpType, value:inpVal})
      //     }
      //   })
      // }
    })
  }

  // ngOnInit(): void {
   
  //       // fromEvent(this.inp()?.nativeElement , 'change').subscribe({
  //       //   next:()=>{
  //       //     let inpVal = this.inp()?.nativeElement.value
  //       //     this.combinedFilteredOutput.emit(inpVal)
  //       //   }
  //       // })
      
  // }


  processInpCombined(val:any){
    //let inpVal = this.inp()?.nativeElement.value
    this.combinedFilteredOutput.emit(val);
  }
}
