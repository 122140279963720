import { Component, Inject, inject, OnInit, Optional } from '@angular/core';
import { Chart, registerables  } from 'chart.js';
Chart.register(...registerables);
@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss'
})
export class ChartComponentA implements OnInit {
      labels:any[] = [];
      dataset_data:number[] = []
      chart:any;
      constructor(
        @Inject('commentCountData') @Optional() public commentCountData:any,
        @Inject('commentsCountData') @Optional() public commentsCountData:any[],
        @Inject('bodyCount') @Optional() public bodyCount:any,
      ){}
      ngOnInit(): void {
        console.log("commentsCountData--",this.commentsCountData,"commentCountData--",this.commentCountData,"bodyCount--",this.bodyCount);
        if(this.commentCountData !== undefined){
          this.labels.push(this.commentCountData?.id);
          this.dataset_data.push(this.commentCountData?.wordCount);
        }
        if(this.commentsCountData !== undefined){
           this.commentsCountData.map((r:any)=>{
             this.labels.push(r.id);
             this.dataset_data.push(r.wordCount);
          });
        }
        if(this.bodyCount !== undefined){
          this.labels.push(this.bodyCount?.id);
          this.dataset_data.push(this.bodyCount?.wordCount);
        } 
        
        
         this.chart = new Chart("barChart", {
            type: 'bar', //this denotes tha type of chart
            data: {
              labels: this.labels,
              datasets: [
                {
                  label: 'Word count of comments',
                  data: this.dataset_data,
                  borderWidth: 1,
                },
              ],
            },
            options: {
              maintainAspectRatio:false,
              indexAxis: 'y',
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            },
          })
        
      }
}
