import { createAction,props } from '@ngrx/store';
import { Note } from './model/note';

export const addNote = createAction('[Note Page] Add', props<Note>());
//export const addNote = createAction('[Note Page] Add]');
export const fetchUsers = createAction('[Note Page Fetch Users Success', props<{query:any,users:any}>());
export const matchedUser = createAction('[Note Page] User Matched', props<{user:any}>());
export const notMatchedUserError = createAction('[User Not Found in NGRX state]', props<{message:any}>())
export const fetchUsersError = createAction('[Note Page Fetch Users Error]', props<{message:any}>())
export const removeNote = createAction('[Note Page] Remove', props<{id:number}>());
export const updateNote = createAction('[Note Page] Update', props<Note>());
export const pinNote = createAction('[Note Page] Pin', props<Note>());
export const unpinNote = createAction('[Note Page] UnPin', props<Note>());
export const selectNote = createAction('[Note Page] Select Note', props<Note>());
export const loadUsers = createAction('[Note Page] Load Users')