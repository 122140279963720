import { Directive, ElementRef, HostListener, inject, Injector, input, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { CommonService } from '../../common.service';
import { concatMap, delay, filter, find, from, interval, map, of, switchMap, take, tap } from 'rxjs';
import { ChartComponentA } from '../../my-notes/chart/chart.component';


@Directive({
  selector: '[sqs],[column_name]',
  standalone: true,
})
export class TooltipDirective {
  _cs=inject(CommonService)
  tempRef = inject(TemplateRef<unknown>)
  vRef = inject(ViewContainerRef)
  column_name=input<string>()
  // constructor() {
  //   this.tempRef.
  // }

  //  @Input() set sqs(content:TemplateRef<unknown> | any) {
  //     this.vRef.createEmbeddedView(content?.templ,{content:content?.id})
  //     console.log("sqs",content?.id)
  //   }

  @Input() set sqs(data:any) {
     console.log(this.column_name());
     switch (data.dataType) {
      case "users":
        if(this.column_name()==='id') {
          
         //   console.log("isId");
          //----------
         // setTimeout(() => {
            this.vRef.createEmbeddedView(this.tempRef, {loading:true});
            this._cs.getPosts().pipe(
              map(r=>r.filter((w:any)=>w.userId==data.content))
            ).subscribe({
              next:(res:any)=>{
                this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}`, result: res,loading:false});
              }
            })
         // }, 600);
        }
        //-----------
        else if(this.column_name()==='website') {
          
          let getWebsiteDomainName= data.content.split(".");
          console.log(getWebsiteDomainName)
          let domainExtension = getWebsiteDomainName[getWebsiteDomainName.length-1];
          let domainExtension2 = domainExtension.trim();
        //  setTimeout(() => {
            this.vRef.createEmbeddedView(this.tempRef, {loading:true});
            this._cs.getUsers().pipe(
              map(t=>t.filter((f:any)=>f.website.includes(domainExtension2)))
            ).subscribe({
              next:(res)=>{
                let otherWesitesSameDomainExt = res.filter((o:any)=>!o.website.includes(getWebsiteDomainName[0]))
                this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}`, result: otherWesitesSameDomainExt, loading:false});
              }
            })
         // }, 600);
        }
        //this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}RRR`}); 
        break;
      case "posts":
        if(this.column_name()==='id') {
          console.log("isId");

          // interval(2000).pipe(
          //   take(5),
          //  // switchMap((d)=>this._cs.getComments().pipe(
          //     //delay(1000),
          //     map(r=>r.filter((w:any) => w.postId == (d+1))),
          //     //map(o=>o),
          //    // filter(t=>t.id == (d+1)),
              
          //   //))
          // )

          this._cs.getComments().pipe(
            map(r=>r.filter((w:any)=>w.postId==data.content)),
            // switchMap((d)=>of(d).pipe(
            //   delay(1000)
            // ))
          )
          .subscribe({
            next:(res:any)=>{
              this.vRef.createEmbeddedView(this.tempRef, {loading:true});
              res.map((r:any)=>r.wordCount = r.body.length)
             
              //console.log("response",res);
              from(res).pipe(
                concatMap((d)=>of(d).pipe(
                  delay(2000)
                ))
              ).subscribe({
                next:(ress:any)=>{
                  this.vRef.clear();

                   const elementInjector = Injector.create({
                    providers: [
                      {
                        provide: 'commentCountData',
                        useValue: ress
                      },
                      {
                        provide: 'commentsCountData',
                        useValue: undefined
                      }
                    ],
                  });
                  this.vRef.createComponent(ChartComponentA, {injector:elementInjector});

                  // this.vRef.createEmbeddedView(this.tempRef, {content: {}, name: ``, result: {}, animateClass:'slide'});
                  this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}`, result: ress, animateClass:'slideIn', animateComplete:false, loading:false});
                },
                complete:()=>{
                  
                  setTimeout(() => {
                    this.vRef.clear();
                    this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}`, result: res, animateClass:'slideIn', animateComplete:true, loading:false});

                    const elementInjector = Injector.create({
                      providers: [
                        {
                          provide: 'commentsCountData',
                          useValue: res
                        },
                        {
                          provide: 'commentCountData',
                          useValue: undefined
                        }
                      ],
                    });
                    this.vRef.createComponent(ChartComponentA, {injector:elementInjector});
                    }, 2000);
                }
              })
            }
          })
        } else if(this.column_name()==='body') {
            console.log("tooltip",data)
            let bodyCount = {
              wordCount:data.content.length
            };
            const elementInjector = Injector.create({
              providers: [
                {
                  provide: 'bodyCount',
                  useValue: bodyCount
                },
                {
                  provide: 'commentsCountData',
                  useValue: undefined
                },
                {
                  provide: 'commentCountData',
                  useValue: undefined
                }
              ],
            });
            console.log("bodyCount",bodyCount);
            this.vRef.createEmbeddedView(this.tempRef, {content: data.content, name: `${data.name}`, loading:false});
            this.vRef.createComponent(ChartComponentA, {injector:elementInjector});
        }
        break;
      default:
        break;
     }
  }
}
