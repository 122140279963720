import { Component, viewChild, ViewChild, viewChildren, OnInit, ElementRef, effect, AfterViewInit, ViewContainerRef, TemplateRef, Injector, createComponent, EnvironmentInjector, createEnvironmentInjector, ApplicationRef, ComponentFactoryResolver,ComponentFactory , ComponentRef, ViewRef, Directive, ɵINPUT_SIGNAL_BRAND_WRITE_TYPE, inject} from '@angular/core';
import { Store } from '@ngrx/store';
import { concatMap, delay, filter, first, from, interval, map, mergeMap, Observable, of, switchMap, take, tap } from 'rxjs';
import { addNote, removeNote, updateNote, pinNote, unpinNote, selectNote } from '../note.actions';
import { AsyncPipe, CommonModule, NgIf } from '@angular/common';
import { Note } from '../model/note';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import { ItemComponent } from './item/item.component';
import { NoteState, noteItems, noteItemsCount, isAnyNotePinned, actionName } from '../note.selector';
import { HeaderComponent } from './header/header.component';
import { SortPipe } from '../utility/sort.pipe';
import { NgForOf, NgFor} from '@angular/common';
import { fromEvent } from 'rxjs';
import { SelectionDirective } from '../utility/directive/selection.directive';
import {FormsModule} from '@angular/forms';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import { CommonService } from '../common.service';
import { ModalDirective } from '../utility/directive/modal.directive';
import { SampleDirective } from '../utility/directive/sample.directive';
import { UnitsInMetersDirective } from '../utility/unitsInMeters.directive';
import { MyDirective } from '../utility/directive/my.directive';
import { StudentComponent } from './student.component';
import { ModalContentDirective } from '../utility/directive/modal-content.directive';
import { ModalComponent } from '../utility/modal/modal.component';
import {Dialog,DialogModule} from '@angular/cdk/dialog';
import { DialogComponent } from '../utility/dialog.component';
import { animate, state, style, transition, trigger, query, stagger } from '@angular/animations';
import { MatIconModule } from '@angular/material/icon';
import { ChartComponentA } from './chart/chart.component';
@Component({
  selector: 'app-my-notes',
  standalone: true,
  imports: [AsyncPipe, ModalDirective, ModalContentDirective, MyDirective,SampleDirective,MatCheckboxModule, FormsModule, CommonModule, SelectionDirective, SortPipe, NgIf,NgFor, NgForOf, MatInputModule, MatIconModule, MatFormFieldModule, MatButtonModule, ItemComponent, HeaderComponent, StudentComponent, ModalComponent, NgFor, ChartComponentA],
  templateUrl: './my-notes.component.html',
  styleUrl: './my-notes.component.scss',
  providers:[CommonService,DialogModule],
  host: {
    '(@listAnimation.start)': 'captureStartEvent($event)',
    '(@listAnimation.done)': 'captureDoneEvent($event)',
  },
  animations:[
    trigger('listAnimation', [
      transition('* <=> *', [
        query(':enter',
          [
            style({ opacity: 0 }),
            stagger('400ms', animate('300ms ease-in',
              style({ opacity: 1 })
            )),
            stagger('400ms', animate('300ms ease-out',
              style({ opacity: 0 })
            )),
            style({ opacity: 0 }),
          ],
          { optional: true }
        ),
        // query(':leave',
        //   animate('200ms', style({ opacity: 0 })),
        //   { optional: true }
        // )
      ])
    ]),
    trigger('appear', [
      state(
        'yes',
        style({
          opacity:1,
          height:'auto',
        }),
      ),
      transition('* => *', [animate('1s ease-out')])
    ]),
    trigger('isAtleast1UserSelected', [
      state(
        'yes',
        style({
          top:'0',
          transform: 'translateY(0)',
        }),
        // animate('300ms ease-in')
      ),
      state(
        'no',
        style({
          transform: 'translateY(-28px)',
          top:'50%'
        }),
      ),
      transition('* => yes', [animate('1s ease-out')]),
      transition('* => no', [animate('0.5s ease-out')])
    ]),
    trigger('isAtleast1UserSelectedBody', [
      state(
        'yes',
        style({
          // transform: 'translateY()',
          opacity:1
        }),
      ),
      state(
        'no',
        style({
          //transform: 'translateX(0)',
          opacity:0
        }),
      ),
      transition('* => *', [animate('1s')])
    ])
  ]
})

export class MyNotesComponent implements OnInit{
  //public notes$ = new Observable<Note[]>();
  public noteItems$ = new Observable<any>();
  public noteItemsCount$ = new Observable<number>();
  public isAnyNotePinned$ = new Observable<any>();
  public actionName$ = new Observable<any>();
  public notesCount:number=1;
  public selectedNote:any=null;
  public isCreateMode:boolean=true;
  public arr = [5,7,3,1,9];
  public dontSort:boolean=false;
  //public postsData:any[]=[];
  public showModal:boolean = false;
  public confirmModal:boolean = false;
  public fetchedData:any[]=[];
  public userSelectedData:any=[];
  public viewRefIns:any;
  public fetchDataType:string='';
  public dialogRef!:ComponentRef<ModalComponent>
  public vref_dynamic_load_component!:ComponentRef<ModalComponent>
  atleast1UserSelectionAnimationState:string='no';
  users:string[]=[];
  addNoteLoading:boolean=false
  //public componentRef: ComponentRef<any> = new ComponentRef<ElementRef>();
  // public samplet = {name: 'aq', age:23};
  postsData = new Observable<any>();
  dialog = inject(Dialog);

  //itemV = viewChild("item");
  //hdr = viewChild<HeaderComponent>("header");
  // hdr = viewChild("header",{read: ElementRef});
  @ViewChild('hdr', {read:ModalDirective}) hdr!:ModalDirective;
  //@ViewChild('hdr') hdr!:ElementRef; // ElementRef {nativeElement: div}
  selectionCheckbox = viewChildren("selectionCheckbox",{read: MatCheckbox});
  itemList = viewChildren<ItemComponent>("item");
  //  @ViewChild('modal', {read:TemplateRef}) modal!:TemplateRef<any>; 

  @ViewChild('modal_concrete_templateref',{read:TemplateRef<any>}) modal_concrete_templateref!:TemplateRef<any>;
  modal_concrete_templateref_ins = viewChild('modal_concrete_templateref', {read:TemplateRef<any>});
  // modal_concrete_viewref_ins = viewChild('modal_concrete_viewref', {read:ViewContainerRef});
  @ViewChild('modal_concrete_viewref',{read:ViewContainerRef}) modal_concrete_viewref!:ViewContainerRef;

  // @ViewChild('vref_dynamic_load_component_ref',{read:ViewContainerRef}) vref_dynamic_load_component_ref!:ViewContainerRef;
  

  constructor(private vref_dynamic_load_component_ref:ViewContainerRef, private modalVref:ViewContainerRef, private store: Store<NoteState>, private _cs:CommonService, private injector: EnvironmentInjector, private appRef: ApplicationRef) {
   // this.notes$ = store.select('notes');
    this.noteItems$ = store.select(noteItems);
    this.noteItemsCount$ = store.select(noteItemsCount);
    this.isAnyNotePinned$ = store.select(isAnyNotePinned);
    this.actionName$ = store.select(actionName);

    this.noteItemsCount$.subscribe({
      next:(w:any)=>console.log("cartItems - count",w)
    });

    // effect(() => { 
    //   setTimeout(() => {
    //     ///let hdrMssg = this.hdr()?.readHeader();
    //     console.log("hdrMssg",this.hdr());
    //   }, 800);
    // });

    this.actionName$.subscribe({
      next:(t:any)=>{
        console.log("actionName",t);
        if(t.type === "[Note Page] User Matched") this.addNoteLoading=false;
      }
    })

    this.noteItems$.subscribe({
      next:(w:any)=>{
        console.log("notesaaa",w);
        //----------
        this.itemList().map((e:ItemComponent,i:number)=>{
          let getName = e.getNoteDetails()?.name;
          console.log("item name is ",getName)
        });
      }
    });
    // store.subscribe({
    //   next:(notes:any)=>console.log("STORE >>",notes),
    //   error:(err:any)=>{
    //     console.log("notes error",err)
    //   }
    // })
  }
  captureStartEvent(event: AnimationEvent) {
    console.log("animation started")
  }

  captureDoneEvent(event: AnimationEvent) {
    console.log("animation complt")
    this.users = ['Bret', 'Antonette', 'Samantha', 'Karianne', 'Kamren', 'Leopoldo_Corkery','Elwyn.Skiles','Maxime_Nienow', 'Delphine', 'Moriah.Stanton'];
  }

  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     //this.hdr?.vref?.detach();
  //     //this.hdr?.vref?.clear();
  //     this.hdr?.addAnotherClass();
  //      this.toggleList();
  //   //console.log("hdrMssg",hh);  
  //   }, 5700);
   
  // }

  ngOnInit(): void {
    //let getHeaderMessage = this.hdr()?.readHeader();
   /// console.log("getHeaderMessage",getHeaderMessage);
    /// this.modalVref.createEmbeddedView(this.modalTempRef()?.elementRef.nativeElement)
    // this.dialogRef.subscribe((result:any) => console.log("result",result))
    //this.dialogRef.instance.subscribe((result:any) => console.log("result",result));

    //this.checkq();

    // this.appRef.components.map()
    setTimeout(() => {
        this.toggleList();
    }, 2000);

  }

  toggleList() {
    this.users = ['Bret', 'Antonette', 'Samantha', 'Karianne', 'Kamren', 'Leopoldo_Corkery','Elwyn.Skiles','Maxime_Nienow', 'Delphine', 'Moriah.Stanton'];
  }

  addNote(noteObj:any) {
    //this.store.dispatch(addNote());
    this.store.dispatch(addNote(noteObj));
    this.addNoteLoading=true;
    // this.notesCount++;
    // this.selectedNote=null;
  } 

  identify(index:any, item:any) {
    return item.id;
}

// checkq(){
//       ///this.vref_dynamic_load_component.changeDetectorRef.detectChanges()
   
//   //this.vref_dynamic_load_component.setInput("keyword","hhhh ttt555")
//    // this.dialogRef.instance.emc.subscribe((r:any) => console.log("emc-callback",r));
// }


  handleNoteAction(data:any){
    console.log("handleNoteAction",data);
    this.dontSort=false;
    switch (data.action) {
      case 'edit':
        this.isAnyNotePinned$.subscribe({
          next: (isAnyNotePinned:boolean) => {
            console.log("any note is Pinned",isAnyNotePinned);
            isAnyNotePinned ? this.dontSort=true : this.dontSort=false;
          }
        })
        this.isCreateMode=false;
        // this.noteItems$.subscribe((selectedNote:any)=>{
        //   console.log("selectedNote",selectedNote);
        // })
        this.noteItems$.pipe(
          map((h:any)=>h.notes.find((r:any)=>r.id == data.val))
        ).subscribe((selectedNote:any)=>{
          this.selectedNote=selectedNote;
        })
        break;
      case 'pin':
        this.isAnyNotePinned$.subscribe({
          next: (isAnyNotePinned:boolean) => {
            console.log("any note is Pinned",isAnyNotePinned);
            isAnyNotePinned ? this.dontSort=true : this.dontSort=false;
          }
        })
        let pinnedNote = {...data?.val}
        pinnedNote.pinned = true;
        this.store.dispatch(pinNote(pinnedNote));
        break;
      case 'unpin':
        this.isAnyNotePinned$.subscribe({
          next: (isAnyNotePinned:boolean) => {
            console.log("any note is Pinned",isAnyNotePinned);
            isAnyNotePinned ? this.dontSort=true : this.dontSort=false;
          }
        })
        let unpinnedNote = {...data?.val}
        unpinnedNote.pinned = false;
        this.store.dispatch(unpinNote(unpinnedNote));
        break;
      case 'remove':
        this.store.dispatch(removeNote({id:data?.val}));
        break;
      case 'select':
        this.store.dispatch(selectNote(data?.val));
        break;
      default:
        break;
    }
  }

  build_viewContainerRef(type:string, data:any, selected:any){
    this.modal_concrete_viewref.createEmbeddedView(this.modal_concrete_templateref,
    {fetchDataType:type, selectedUsers:selected},
      {
        index:0,
        injector: Injector.create({
          providers:[{ provide: 'userDetails', useValue: data}]
        })
      }
    );
  }

  selectUsers(){
    this.selectionCheckbox().map((cb:MatCheckbox)=>{
        console.log("selectionCheckbox",cb)
        if(cb.checked) {let idNum = parseInt(cb.id); this.userSelectedData.push(idNum);}
      })
    }

  // fetch Posts 
  getPosts(dataType:string) {
      this.userSelectedData = [];
      this.selectUsers();
      this.fetchDataType=dataType;
      console.log("this.userSelectedData",this.userSelectedData);
      //this.userSelectedData = [1,3,5,8,2];
      if(dataType=='posts'){
        this.postsData = of(this.userSelectedData).pipe(
          concatMap((a:any) => this._cs.getPosts().pipe( 
            map((r:any)=>r.filter((p:any)=>this.userSelectedData.includes(p.userId))),
            tap((g)=>console.log("getPosts >>",g))
          ))
          // concatMap((a:any) => this._cs.getPosts().pipe( 
          //     switchMap((v:any) => this._cs.getUsers())
          // )
        );
      } else {
        this.postsData = of(this.userSelectedData).pipe(
          concatMap((a:any) => this._cs.getUsers().pipe( 
            map((r:any)=>r.filter((p:any)=>this.userSelectedData.includes(p.id))),
            //tap((g)=>console.log("getPosts >>",g))
          ))
        );
      }
  }
  openModal1(){
    this.postsData.subscribe({
      next:(w:any)=> {
        console.log('fetch-data',w);
        return w.length ?this.showModal=true:''
      }
    });
  }

  // fetch Posts and pass them to 'modal' component via Injection Token
  get_and_passTo_createEmbeddedView_and_Component(dataType:string) {
      this.getPosts(dataType)
      this.postsData.subscribe({
        next:((userDetails:any)=>this.build_viewContainerRef('posts',userDetails,this.userSelectedData))
      })
      //this.showModal=true;
  }
  get_and_passTo_Component(dataType:string) {
      const EnvironmentInjector = createEnvironmentInjector([
        {
          provide: 'MyToken',
          useValue: 'Token'
        }
      ], this.injector);
      this.getPosts(dataType)
      this.postsData.subscribe({
        next:((userDetails:any)=>{
          const elementInjector = Injector.create({
            providers: [
              {
                provide: 'userDetails',
                useValue: userDetails
              }
            ],
          });
          //this.build_viewContainerRef('posts',userDetails,this.userSelectedData);
          this.dialogRef = createComponent(ModalComponent,
            {
              environmentInjector: this.injector,
              elementInjector,
              hostElement: document.getElementById('modal-load-container')!
            }
          );
          this.dialogRef.setInput('keyword','1234567');
          this.appRef.attachView(this.dialogRef.hostView);
          this.dialogRef.changeDetectorRef.detectChanges();
        })
      })
  }
  get_viewcontainerref_createcomponent(dataType:string) {
    this.getPosts(dataType)
    this.postsData.subscribe({
      next:((userDetails:any)=>{
        // const elementInjector = Injector.create({
        //   providers: [
        //     {
        //       provide: 'userDetails',
        //       useValue: userDetails
        //     }
        //   ],
        // });
        // this.vref_dynamic_load_component = this.vref_dynamic_load_component_ref.createComponent(ModalComponent, {
        //   injector:elementInjector
        // });

        this._cs.openModal(this.vref_dynamic_load_component_ref,userDetails)
      

        // this.vref_dynamic_load_component.instance.eventModalClose.subscribe((r:any) => this.vref_dynamic_load_component_ref.clear());
        // let userDetailsId : number[] = [];
        // userDetails.map((r:any) => userDetailsId.push(r.id));
        // this.vref_dynamic_load_component.instance.selectedUsers = userDetailsId;
      })
    });
  }
  openUsersPostsDialog(dataType:string){
    this.getPosts(dataType)
    this.postsData.subscribe({
      next:((userDetails:any)=>{
        let userDetailsId : number[] = [];
        userDetails.map((r:any) => userDetailsId.push(r.id));
        this._cs.openDialog(dataType, userDetails, userDetailsId).closed.subscribe({
          next:(w)=>console.log('users/posts closed',w)
        })
        // let dRef = this.dialog.open(DialogComponent,{
        //   maxHeight: 'calc(100% - 80px)',
        //   minHeight: '400px',
        //   width: '1200px',
        //   height: '100%',
        //   data: {
        //     title: `${dataType} list`,
        //     dataType:dataType,
        //     selectedUsers: userDetailsId,
        //     data: userDetails
        //   }
        // });
        // dRef.closed.subscribe({
        //   next:(w)=>console.log('users/posts closed',w)
        // })
      })
    });
    
  }

  handleModalClose(e:any){
    console.log("handleModalClose",e);
    //this.appRef.detachView(this.dialogRef.hostView);
  }

  // removeNote(id:number){
  //   this.store.dispatch(removeNote({id}));
  // }
  // editNote(id:number){
  //   this.isCreateMode=false;
  //   this.notes$.pipe(
  //     map((h:any)=>h.find((r:any)=>r.id == id))
  //   ).subscribe((selectedNote:any)=>{
  //     this.selectedNote=selectedNote;
  //   })
  // }
  updateNote(noteObj:any){
    this.store.dispatch(updateNote(noteObj));
    this.isCreateMode=true;
    this.selectedNote=null;
  }

  modalEventOutput(data:any){
    console.log("modalEventOutput",data);
  }
}
