import { Directive, ElementRef, HostBinding, HostListener, input, Input, Renderer2, contentChild, effect} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';

@Directive({
  selector: '[recordSelection]',
  standalone: true
})
export class SelectionDirective {
  recordSelection = input();
  sc = contentChild("selectionCheckbox", {read:MatCheckbox})

  constructor(private el:ElementRef, private r2:Renderer2) {
    effect(()=>{
      console.log("selectionCheckboxPrint",this.recordSelection(),this.sc()?.checked);
    })
  }

  @HostListener ('click') onClick(f:any) {
    console.log("ON.CLICK this is a directive",this.el,f,this.recordSelection());
   // let g:MatCheckbox | any = document.querySelector(".clicked .mat-mdc-checkbox");
    //g.checked=false;
    //document.querySelector(".clicked")?.classList.remove("clicked");
    
    if(this.sc()?.checked) this.r2.removeClass(this.el.nativeElement, 'clicked')
    else this.r2.addClass(this.el.nativeElement, 'clicked')
    this.sc()?.toggle();
    
  };

  @HostListener ('mouseenter') onMouseEnter(f:any) {
    this.r2.addClass(this.el.nativeElement, 'hovered');
  }

  @HostListener ('mouseleave') onMouseLeave(f:any) {
    this.r2.removeClass(this.el.nativeElement, 'hovered');
  }

  // @HostListener ('blur') onBlur(f:any) {
  //    console.log("ON.BLUR---this is a directive",this.el,f);
  //   document.querySelector(".clicked")?.classList.remove("clicked");
  //   this.r2.addClass(this.el.nativeElement, 'clicked');
  //   this.sc()?.toggle();
  // };
}
