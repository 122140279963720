import {createSelector} from '@ngrx/store';
import { Note } from './model/note';

export interface NoteState {
  nnn: Note[],
  numberOfItems: number
}

export const selectCartState = (state: NoteState) => state;

export const noteItems = createSelector(
  selectCartState,
  (state: any | undefined) => {
    return state.notex.users;
  }
);

export const noteItemsCount = createSelector(
  selectCartState,
  (state: any | undefined) => {
    return state.notex.users.length;
  }
);

export const isAnyNotePinned = createSelector(
  selectCartState,
  (state:any | undefined) => {
    return state.notex.users.some((r:any)=>r.pinned)
  }
)


export const actionName = createSelector(
  selectCartState,
  (state:any | undefined) => {
    return state.notex.actionName !== null ? state.notex.actionName : null;
  }
)