import { Directive, ElementRef, HostListener, inject, input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTooltipShow], [top], [left], [popo]',
  standalone: true
})
export class TooltipShowDirective {
  r2 = inject(Renderer2)
  el = inject(ElementRef)
  appTooltipShow=input<any>();
  top=input<number>();
  left=input<number>();
  popo=input<any>();
  constructor() {
    this.r2.addClass(this.el.nativeElement, 'tooltip');
  }

  // tooltipActivate(){
  //   this.r2.setStyle(this.el.nativeElement, "top", `${x+20}px`)
  //   this.r2.setStyle(this.el.nativeElement, "left", `${y+20}px`)
  // }
  
  @HostListener ('mouseenter') onMouseEnter(f:any) {
    
    //this.r2.removeClass(this.el.nativeElement, 'hidden');
  }
  // @HostListener ('mouseleave') onMouseLeave(f:any) {
  //   setTimeout(() => {
  //      console.log('on-leave');
  //       this.r2.addClass(this.el.nativeElement, 'hidden');
  //   }, 1300);
  // }

  // @HostListener ('mouseover') onMouseOver(f:any) {
  //   this.r2.removeClass(this.el.nativeElement, 'hidden');
  // }
  // @HostListener ('mouseout') onMouseOut(f:any) {
  //    setTimeout(() => {
  //       console.log('on-leave');
  //       this.r2.addClass(this.el.nativeElement, 'hidden');
  //   }, 1300);
  // }

  // @HostListener ('mousemove') onMouseMove(t:any) {
  //   this.r2.setStyle(this.el.nativeElement, "top", this.top)
  //   this.r2.setStyle(this.el.nativeElement, "left", this.left)
  // }

  // enter(){
  //   this.r2.removeClass(this.el.nativeElement, 'hidden');
  //   console.log("popo",this.popo())
  // }
  // leave(){this.r2.addClass(this.el.nativeElement, 'hidden')}

  move(y:number,x:number) {
    let nv = this.el.nativeElement.offsetHeight;
    let elHt_y = nv+y
    ///
    this.r2.removeClass(this.el.nativeElement, 'hidden');
    let ty = 0;
    //let tx = 20;
    if(elHt_y > window.innerHeight) {
      ty = (y - nv) - 20;
      if(ty <= 0) ty = 0
    }
    else ty = y+20;
    this.r2.setStyle(this.el.nativeElement, "top", `${ty}px`);
    this.r2.setStyle(this.el.nativeElement, "left", `${x+20}px`);
  }
}
