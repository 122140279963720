import { Component, Inject } from "@angular/core";

@Component({
    selector: 'app-student',
    standalone:true,
    template: `
        <ng-template let-stu="students">
        @for(std of stu; track std.name){
            <div>
                <p>{{std.name}} - {{std.age}}</p>   
            </div>
        }
    </ng-template>
    `
})
export class StudentComponent {
    constructor(@Inject('students') public students: any[]) {}
} 