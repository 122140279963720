import { AfterViewInit, ChangeDetectionStrategy, Component, contentChild, contentChildren, effect, ElementRef, input, Input, model, OnInit, output, QueryList, viewChild, viewChildren } from '@angular/core';
import { Note } from '../../model/note';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-item',
  standalone: true,
  imports: [],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ItemComponent implements OnInit,AfterViewInit {
  //@Input() note:Note = {id:0,name:'',age:0,pinned:false};
  //public sam = model.required<any>();
  public note = input<Note>();
  emitAction = output<any>();

  noteItems = contentChildren("noteItem", {read: ElementRef});
  noteItemId = contentChild("noteItemId", {read: ElementRef});

  constructor() {
    effect(()=>{
      this.noteItems().map((c:ElementRef,i:number)=>{
        console.log(`content-child-note-item @ ${i} (index) :`, c.nativeElement.textContent);
      });
      console.log(`content-child-note-item-id :`, this.noteItemId()?.nativeElement.textContent);
    })
  }

  ff = viewChildren<any>('link')

  ngOnInit(): void {
   // this.noteItems
  }

  ngAfterViewInit() {
    this.ff().map((r:ElementRef)=>{
        fromEvent(r.nativeElement,'click').subscribe({
          next:(q:any)=>console.log("fromEvent2",q.target.text)
        });
    })
  }


  getNoteDetails=()=>this.note()
  editNote=(id:number | undefined) => this.emitAction.emit({action:'edit',val:id});
  removeNote=(id:number | undefined) => this.emitAction.emit({action:'remove',val:id});
  pinNote=(noteObj:Note | undefined) => this.emitAction.emit({action:'pin',val:noteObj});
  unpinNote=(noteObj:Note | undefined) => this.emitAction.emit({action:'unpin',val:noteObj});
  selectNote=(noteObj:Note | undefined) => this.emitAction.emit({action:'select',val:noteObj});
  // updateNote=(note:Note) => this.emitAction.emit({action:'update',val:note});
}
