<app-header>
  <h6 a2>ZZZ</h6>
  <h2 a1>iuyi</h2>
</app-header>
<div class="container mx-auto">
  <div class="logo absolute top-5 left-5">
    <img [src]="'logo.png'" alt="Users" width="140" title="Users" />
  </div>
  <!-- <button (click)="toggleList()">toggle list</button> -->

  <div class="boxy absolute left-0 right-0" [@isAtleast1UserSelected]="(noteItems$ | async)?.length ? 'yes' : 'no'">
    <div>
      <div class="flex justify-center items-start gap-4">
        <mat-form-field appearance="fill" class="flex-auto">
          <mat-label>User Name</mat-label>
          <input matInput placeholder="Name" #noteVal [value]="selectedNote != null ? selectedNote?.name : ''">
          <!-- <mat-hint class="whitespace-pre">Search with : Bret, Antonette, Samantha, Karianne, Kamren, Leopoldo_Corkery,
            Elwyn.Skiles, Maxime_Nienow, Delphine, Moriah.Stanton</mat-hint> -->
        </mat-form-field>

        <!-- <mat-form-field appearance="fill">
          <mat-label>Age</mat-label>
          <input matInput placeholder="Age" #ageVal [value]="selectedNote != null ? selectedNote?.age : ''">
        </mat-form-field> -->

        @if(this.isCreateMode) {
        <button mat-fab extended [color]="'primary'" (click)="addNote({id:notesCount, name:noteVal.value, age:0});">
          <mat-icon>person_add</mat-icon>Add User
          @if(addNoteLoading) {<mat-icon class="loader">refresh</mat-icon>}
        </button>
        <!-- <button mat-raised-button (click)="addNote({id:1, name:'Bret', age:23});">Add
        Note</button> -->
        } @else {
        <button mat-fab extended [color]="'secondary'"
          (click)="updateNote({id:selectedNote.id, name:noteVal.value, age:0})">
          <mat-icon>person_add</mat-icon>Update User
        </button>
        }
      </div>
      <div class="flex  text-xs">
        <label class="whitespace-pre">Search with<br>any username: </label>&nbsp;
        <div [@listAnimation]="users.length" class="items">
          <div *ngFor="let item of users" class="item font-medium"><a class="link cursor-pointer"
              [title]="'Add user '+item" (click)="noteVal.value=item">{{item}}</a>,&nbsp;
          </div>
        </div>
      </div>
      @if((actionName$ | async)?.type != null) {
      <div class="mt-1">Error: {{(actionName$ | async)?.type}}</div>
      }
    </div>


    <div class="relative mt-16" [@isAtleast1UserSelectedBody]="(noteItems$ | async)?.length ? 'yes' : 'no'">
      <div>Any Not Pinned?: {{isAnyNotePinned$ | async}}</div>
      <div>Note Item Count: {{noteItemsCount$ | async}}</div>

      <!-- <div><a (click)="getPosts('users');openModal1();">Get Users</a> | <a (click)="getPosts('posts');openModal1();">Get
          Posts</a>
      </div> -->
      <!-- <div><a (click)="get_and_passTo_createEmbeddedView_and_Component('users')">Get Users - and pass to
          createEmbeddedView & 'modal' component via
          Context & Injection Token</a> | <a (click)="get_and_passTo_createEmbeddedView_and_Component('posts')">Get
          Posts -
          and pass to
          createEmbeddedView & 'modal' component via
          Context & Injection Token</a>
      </div> -->
      <!-- <div><a (click)="get_and_passTo_Component('users')">Get Users - and pass to 'modal' component</a> | <a
          (click)="get_and_passTo_Component('posts')">Get Posts - and pass to 'modal' component</a></div>
        -->
      <!-- <div>
        <a (click)="get_viewcontainerref_createcomponent('users')">Get Users - and pass to 'modal' component
          (viewcontainerref_createcomponent)</a>
        | <a (click)="get_viewcontainerref_createcomponent('posts')">Get Posts - and pass to 'modal' component
          (viewcontainerref_createcomponent)</a>
      </div> -->

      <div class="flex gap-4 mt-2 mb-2">
        <button mat-raised-button color="secondary" (click)="openUsersPostsDialog('users')">
          <mat-icon>person</mat-icon>Fetch Details of Selected Users
        </button>
        <button mat-raised-button color="secondary" (click)="openUsersPostsDialog('posts')">
          <mat-icon>local_post_office</mat-icon>Fetch Posts Details of Selected Users
        </button>
      </div>



      <div class="selected-users-list">
        @if((noteItems$ | async)?.length){
        @for (note of (noteItems$ | async) | sort: 'asc':dontSort; track note; let i = $index) {
        <ng-container [ngTemplateOutlet]="noteItem" [ngTemplateOutletContext]="{note:note}"></ng-container>
        }
        }
      </div>

    </div>


    <ng-template let-note="note" #noteItem>
      <!-- <p>Value Received from the Parent is {{value}}</p> -->
      <div class="relative opacity-0 h-0 overflow-hidden mb-5" [@appear]="'yes'">
        <div [recordSelection]="note?.id" class="cursor-pointer selectedUsersCheckboxAction"
          [title]="'Click for Selection'">
          <mat-checkbox #selectionCheckbox class="example-margin" data-id="{{note?.id}}">Check me!</mat-checkbox>
        </div>
        <app-item #item [note]="note" (emitAction)="handleNoteAction($event)">
          <!-- <div #noteItem #noteItemId class="cell grid-item" noteId><span>{{note?.id}}</span></div> -->
          <div #noteItem class="cell grid-item" noteName><b>{{note?.name}}</b></div>
          <!-- <div #noteItem class="cell grid-item" noteAge><i>{{note?.age}}</i></div> -->
          <div #noteItem class="cell grid-item" noteEmail><i>{{note?.email}}</i></div>
          <div #noteItem class="cell grid-item" noteUsername><i>{{note?.username}}</i></div>
          <!-- <div #noteItem class="cell grid-item" noteWebsite><i>{{note?.website}}</i></div> -->
        </app-item>
      </div>
    </ng-template>

    <!-- <ng-template let-note="note" #noteItemUser>
  <div [recordSelection]="note?.id">
    <mat-checkbox #selectionCheckbox class="example-margin" data-id="{{note?.id}}">Check me!</mat-checkbox>
    <app-item #item [note]="note" (emitAction)="handleNoteAction($event)">
      <div #noteItem class="cell grid-item" noteAddressCity><i>{{note?.address?.city}}</i></div>
      <div #noteItem class="cell grid-item" noteAddressZipcode><i>{{note?.address?.zipcode}}</i></div>
      <div #noteItem class="cell grid-item" noteCompanyName><i>{{note?.company?.name}}</i></div>
    </app-item>
  </div>
</ng-template> -->


    <!--[ 1 ]------ (Modal Created with 'ViewContainerRef' and 'TemplateRef') ----------------------------->
    <!-- @if(showModal) {
<div [modalShow]="4" class="modal-container" (eventOutput)="modalEventOutput($event)">
  <a #close (click)="showModal = false">Close</a>
  <a (click)="confirmModal = true">Confirm Modal</a>
  <div class="modal">
    <div *modalContent="{myVar:'www4w',data: fetchDataType ==='posts' ? modalPosts : modalUsers}">Modal Created
      with 'ViewContainerRef' andv
      'TemplateRef'
    </div>
  </div>
</div>
}

@if(confirmModal){
<div [modalShow]="4" class="modal-container confirm" (eventOutput)="modalEventOutput($event)">
  <a #close (click)="confirmModal = false">Close</a>
  <div class="modal">
    <ng-container *modalContent="{myVar:'modal confirmation',data: fetchDataType==='posts' ? modalPosts : modalUsers}">
      Modal Confirmation
    </ng-container>
  </div>
</div>
} -->


    <!-- {{(postsData | async)}} -->
    <!-- <ng-template #modalPosts let-postsDataq="postsData">
  <h2>Posts</h2>
  <div *ngFor="let p of (postsData | async); ngForTrackBy:identify">{{p?.userId}} -
    {{p?.title}}</div>
</ng-template>
<ng-template #modalUsers let-postsDataq="postsData">
  <h2>Users</h2>
  @for(p of (postsData | async); track p.id) {
  <div>{{p?.id}} - {{p?.name}} - Company name: {{p?.company?.name}} - City: {{p?.address?.city}}
    -ZipCode:{{p?.address?.zipcode}}</div>
  }
</ng-template> -->

    <!-- Confirm modal -->
    <!-- <ng-template #modalConfirm>
  <p>Confirm modal/p>
</ng-template> -->



    <!----[ 2 ] (concretepage.com) -----Render component in 'TmplateRef' in 'VCRef' --------start-------------->
    <!-- <ng-template #modal_concrete_templateref let-datatype="fetchDataType" let-dataFetched="dataFetched"
  let-selectedUsers="selectedUsers">

   <h4>Usr list 3</h4>
  @for(p of (postsData | async); track p.id){
  {{p.id}} - {{p.title}}
  }
  <span> We are fetching '{{datatype}}' details from selected users {{selectedUsers}} </span> 

  <a #close (click)="showModal = false">Close</a>
  <userdetails-modal></userdetails-modal>
</ng-template>
<div #modal_concrete_viewref> </div> -->
    <!-- (concretepage.com) -------- component example ---end------->

    <!-- (concretepage.com) - directive ex  ample ---->
    <!-- <ng-container *myDir>
  wwee 456456
  <app-student></app-student>
</ng-container> -->


    <!----[ 3 ] ----- Render dynamic component via 'createComponent' API ---------->
    <!-- <div id="modal-load-container">Container for Loading dynamic MOdalComponent
</div> -->


    <!----[ 4 ] ----- Render dynamic component via 'ViewContainerRef'-'createComponent' API ----------->
    <!-- <div #vref_dynamic_load_component_ref>vref dynamic load component ref</div> -->

    <!-- <a (click)="checkq()">checkq</a> -->

  </div>
</div>
