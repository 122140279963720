import { Directive, ViewContainerRef, TemplateRef, Input, OnInit, input,output, inject, AfterViewInit, HostListener, Renderer2, ElementRef, contentChild } from '@angular/core';

@Directive({
  selector: '[modalShow]',
  standalone: true
})

export class ModalDirective {
  vref=inject(ViewContainerRef)
  r2=inject(Renderer2)
  el=inject(ElementRef)
  constructor(){}
  modalShow = input();
  eventOutput = output<{}>();
  c = contentChild('close',{read:HTMLAnchorElement});

  @HostListener ('click') onClick(f:any) {
    //this.c()?.click();
    this.eventOutput.emit({action:'mouseenter'})
  }

  @HostListener ('mouseenter') onMouseEnter(f:any) {
    this.r2.addClass(this.el.nativeElement, 'hovered');
  }

  addAnotherClass(){
    this.r2.addClass(this.el.nativeElement, 'OTHERCLASS');
  }
}
