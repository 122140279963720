import { Component, ComponentRef, inject, Injectable, Injector, ViewContainerRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalComponent } from './utility/modal/modal.component';
import { DialogComponent } from './utility/dialog.component';
import {Dialog} from '@angular/cdk/dialog';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  vref_dynamic_load_component_ref!:ViewContainerRef
  vref_dynamic_load_component!:ComponentRef<any>

  //comp = inject<any>(Component)
 // mdl = inject(ModalComponent)
 dialog = inject(Dialog)
  constructor() { }
  http = inject(HttpClient);
  // dialogUsersPostsRef = inject(DialogModule)
  usersAPI = 'https://jsonplaceholder.typicode.com/users';
  postsAPI = 'https://jsonplaceholder.typicode.com/posts'
  commentsAPI = 'https://jsonplaceholder.typicode.com/comments'

  getUsers(){
    return this.http.get<any>(this.usersAPI);
  }

  getPosts(){
    return this.http.get<any>(this.postsAPI);
  }

  getComments(){
    return this.http.get<any>(this.commentsAPI);
  }

  openModal(vref:ViewContainerRef,data:any[]){
    const elementInjector = Injector.create({
      providers: [
        {
          provide: 'userDetails',
          useValue: data
        }
      ],
    });
    this.vref_dynamic_load_component = vref.createComponent(ModalComponent, {
      injector:elementInjector
    });
    this.vref_dynamic_load_component.instance.eventModalClose.subscribe((r:any) => vref.clear());
    let userDetailsId : number[] = [];
    data.map((r:any) => userDetailsId.push(r.id));
    this.vref_dynamic_load_component.instance.selectedUsers = userDetailsId;
  }


  openDialog(dataType:string,contentDetails:any[],contentDetailsId:number[]){
    return this.dialog.open(DialogComponent,{
      maxHeight: 'calc(100% - 80px)',
      minHeight: '400px',
      width: '1200px',
      height: '100%',
      data: {
        title: `${dataType} list`,
        dataType:dataType,
        selectedUsers: contentDetailsId,
        data: contentDetails
      }
    });
  }
}
